<template>
    <div id="paperDetails">
        <Header />
        <PaperDetailsPC class="paper-details"  v-if="deviceInfo.pc || deviceInfo.tabletPc" />
        <div class="paper-details" v-else></div>
        <Fotter />
        <Watermark />

        <PcDialog v-if="!(deviceInfo.pc || deviceInfo.tabletPc)" />
    </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Fotter from '@/components/layout/Fotter.vue'
import Watermark from '@/components/Watermark'
import PcDialog from '@/components/paper/PcDialog'
import PaperDetailsPC from './PaperDetailsPC.vue'
import { mapGetters } from 'vuex'

export default {
    components: { Header, Fotter, PaperDetailsPC, PcDialog, Watermark },
    computed: { ...mapGetters(['deviceInfo']) },
}
</script>
<style lang="scss" scoped>
#paperDetails {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .paper-details {
        flex-grow: 1;
    }
}
</style>
